import { Dispatch } from 'redux'

import { Box } from '@library/menu-service'

import { actionTypes } from '../actionTypes'

export function boxesDataReceived(response: Box[]) {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: actionTypes.BOXES_DATA_RECEIVED,
      response,
    })
  }
}
