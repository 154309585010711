import React from 'react'

import { Text, FontFamily, FontWeight, Color, Display } from '@gousto-internal/citrus-react'
import { Recipe } from '../../model/recipe'

export const ProteinText = ({ numPortions, recipe }: { numPortions: number; recipe: Recipe }) => {
  const { isFineDineIn } = recipe

  const proteinToDisplay = recipe.protein?.[`for${numPortions}`]

  if (!proteinToDisplay) {
    return null
  }
  const roundedProtein = parseFloat((proteinToDisplay / 1000).toFixed(1))

  return (
    <Text
      size={2}
      fontFamily={FontFamily.Book}
      fontWeight={FontWeight.Normal}
      color={isFineDineIn ? Color.White : Color.ColdGrey_600}
      display={Display.Inline}
      data-testid={'protein-text'}
    >
      &nbsp;&nbsp;|&nbsp;&nbsp;{`${roundedProtein}g protein`}
    </Text>
  )
}
