import { mediaTransformer } from './recipes/mediaTransformer'
import {
  surchargeTransformer,
  isFineDineInTransformer,
  isNewTransformer,
} from './recipes/recipeHelpers'

function recipeTransformer({
  normalisedAttributes,
  individualRecipeId,
  isFeaturedRecipe,
  activeMenu,
  recipeMeta,
  uuid,
}) {
  return {
    boxType: normalisedAttributes.box_type ? normalisedAttributes.box_type.slug : '',
    prepTimes: normalisedAttributes.prep_times,
    coreRecipeId: normalisedAttributes.core_recipe_id.toString(),
    description: normalisedAttributes.description,
    dietType: normalisedAttributes.diet_type ? normalisedAttributes.diet_type.slug : '',
    id: individualRecipeId,
    isFeaturedRecipe,
    meals: [
      {
        numPortions: 1,
        surcharge: surchargeTransformer(normalisedAttributes.surcharges.for1),
      },
      {
        numPortions: 2,
        surcharge: surchargeTransformer(normalisedAttributes.surcharges.for2),
      },
      {
        numPortions: 3,
        surcharge: surchargeTransformer(normalisedAttributes.surcharges.for3),
      },
      {
        numPortions: 4,
        surcharge: surchargeTransformer(normalisedAttributes.surcharges.for4),
      },
      {
        numPortions: 5,
        surcharge: surchargeTransformer(normalisedAttributes.surcharges.for5),
      },
    ],
    media: {
      images: mediaTransformer(normalisedAttributes.images, normalisedAttributes.name),
    },
    rating: {
      count: normalisedAttributes.rating ? normalisedAttributes.rating.count : 0,
      average: normalisedAttributes.rating ? normalisedAttributes.rating.average : 0,
    },
    dietaryClaims: normalisedAttributes.dietary_claims,
    isFineDineIn: isFineDineInTransformer(normalisedAttributes),
    title: normalisedAttributes.name,
    isNew:
      activeMenu && isNewTransformer(activeMenu.relationships.debut_recipes, individualRecipeId),
    foodBrand: normalisedAttributes.food_brand,
    tagline: recipeMeta.tagline,
    availability: recipeMeta.availability,
    portionSizesOffered: recipeMeta.portion_sizes_offered || [2, 4],
    uuid,
    calories: normalisedAttributes.calories,
    invalidPreference: normalisedAttributes.invalid_preference,
    protein: normalisedAttributes.protein,
  }
}

const recipesTransformer = (activeMenu, menuServiceData) => {
  if (!activeMenu || !activeMenu.relationships) {
    return undefined
  }

  const activeMenuRecipesMeta = activeMenu.relationships.recipes.data.reduce((acc, recipe) => {
    const id = recipe.core_recipe_id.toString()
    acc[id] = recipe.meta || {}

    return acc
  }, {})

  return Object.entries(activeMenuRecipesMeta).map(([individualRecipeId, recipeMeta], index) => {
    const currentRecipe = menuServiceData.recipe[individualRecipeId]
    const normalisedAttributes = currentRecipe && currentRecipe.attributes

    // use the first recipe as the featured one (base on recommendations)
    const isFeaturedRecipe = index === 0

    return recipeTransformer({
      normalisedAttributes,
      activeMenu,
      individualRecipeId,
      isFeaturedRecipe,
      recipeMeta,
      uuid: currentRecipe.id,
    })
  })
}

/**
 * The goal of this function is to map recipes from menu service
 *
 * @param recipes
 * @returns {*}
 */
function menuRecipeMapper(recipes) {
  return recipes.map((recipe) =>
    recipeTransformer({
      normalisedAttributes: recipe.attributes,
      activeMenu: undefined,
      individualRecipeId: recipe.attributes.core_recipe_id,
      isFeaturedRecipe: false,
      recipeMeta: {},
      uuid: recipe.id,
    }),
  )
}

export { recipesTransformer, menuRecipeMapper }
