import { Action } from 'redux'

import { Box } from '@library/menu-service/queries/menuApiBoxes/types'

import { actionTypes } from 'actions/actionTypes'

export type MenuBoxesState = {
  boxesV3: {
    availableBoxes: Box[] | Record<string, never>
    isLoading: boolean
  }
}

const boxesInitialState: MenuBoxesState['boxesV3'] = {
  availableBoxes: {},
  isLoading: true,
}

export type BoxesAction = Action<string> & { response: Box[] }

export const boxesReducers = {
  boxesV3: (state = boxesInitialState, action: BoxesAction) => {
    switch (action.type) {
      case actionTypes.BOXES_DATA_RECEIVED: {
        return {
          ...state,
          availableBoxes: action.response,
          isLoading: false,
        }
      }

      default:
        return state
    }
  },
}
