import { composeFetch } from '@library/http'
import { getAuthUserId } from '@library/auth'
import {
  composeRequest,
  addPath,
  setServiceUrl,
  setMethod,
  setQueryParams,
  setHeaders,
  auth,
} from '@library/http/requests'
import { Box, BoxApi } from './types'

const menuApiBoxesParse = async (response: Response): Promise<Box[]> => {
  const body = await response.text()
  const parsedBody = JSON.parse(body)

  if (!response.ok) {
    throw new Error(parsedBody.message)
  }

  return parsedBody.boxes.map((box: BoxApi) => ({
    id: box.id,
    numberOfPortions: box.number_of_portions,
    numberOfRecipes: box.number_of_recipes,
  }))
}

const menuApiBoxesRequest = composeRequest(
  setServiceUrl('menu', 3),
  addPath(() => 'boxes'),
  auth,
  setQueryParams(() => {
    const userId = getAuthUserId()

    return {
      ...(userId && { user_id: userId }),
    }
  }),
  setHeaders((input: { sessionId: string }) => {
    return {
      ...(input.sessionId ? { 'x-gousto-device-id': input.sessionId } : {}),
    }
  }),
  setMethod('GET'),
)

export const boxFetcher = composeFetch(menuApiBoxesRequest, menuApiBoxesParse)
